.approach-numbers {
    & > div {
        max-width: 168px;
        width: max-content;

        @include max-w-md {
            max-width: none;
            margin-top: 12px;
        }
    }

    & :not(:last-child) {
        margin-right: 51px;
    }
}

.approach-container {
    @include max-w-lg {
        max-width: 340px;
    }

    @include max-w-md {
        max-width: 570px;
    }
}
