.text-section {
    background-image: url("../images/svg/policy-bg.svg");

    & > div {
        & p:first-of-type {
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            color: $gray;
            margin-top: 20px;
            margin-bottom: 44px;

            @include max-w-sm {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 32px;
            }
        }

        & > p {
            margin-bottom: 34px;

            @include max-w-sm {
                margin-bottom: 24px;
            }
        }

        & :nth-last-of-type(1) {
            margin-bottom: 0;
        }
    }

    & .text-container {
        position: relative;
        z-index: 1;
        width: 100%;
        max-width: 1381px;
        padding: 226px 295px 134px 295px;
        margin: 0 auto;
        background-image: url("../images/svg/policy-decor.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        @include max-w-lg {
            padding: 120px 95px;
        }

        @include max-w-md {
            padding: 100px 20px 64px 20px;
        }
    }

    &__decor {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;

        @include max-w-md {
            display: none;
        }

        & :first-child {
            position: absolute;
            top: 50%;
            left: 15%;
            min-width: 73px;
            min-height: 73px;
        }

        & :last-child {
            position: absolute;
            top: 25%;
            right: 15%;
            min-width: 73px;
            min-height: 73px;
        }
    }
}
