.leadership {
    background-image: url("../images/projects-bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;

    &__decor {
        top: -160px;
        right: -40%;
        z-index: 1;
        width: 100%;
        height: 100%;

        & img:last-of-type {
            position: absolute;
            left: -75%;
            top: initial;
            bottom: -300px;
            transform: rotate(129.7deg);
        }
    }

    &-section {
        padding-top: 295px;
        padding-bottom: 90px;
        background-image: url("../images/svg/leadership-bg.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        overflow: hidden;

        @include max-w-md {
            padding-top: 48px;
        }
    }

    &__text {
        @include max-w-md {
            min-width: 0;
        }
    }

    &__card-container {
        @include max-w-md {
            margin-left: 0;
        }
    }

    &__card {
        background-image: url("../images/leadership-card-bg.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top;
        width: 520px;
        height: 563px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: flex-start;
        z-index: 2;
        border-radius: 20px;

        @include max-w-lg {
            width: 420px;
            height: 453px;
        }

        @include max-w-md {
            margin-top: 130px;
        }

        @include max-w-sm {
            width: 320px;
            height: 339px;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 20px;
            box-shadow: inset 0 -200px 50px -100px #00000083;
        }
    }

    &__name {
        z-index: 3;
        margin: 0 0 36px 36px;

        @include max-w-sm {
            margin: 0 0 24px 26px;
        }
    }

    &__person {
        bottom: 0;
        left: 33px;
    }

    &__social {
        & :not(:first-child) {
            margin-left: 16px;
        }
    }

    & .our-projects {
        background-image: url("../images/svg/our-project-decor.svg");
        background-repeat: no-repeat;
        background-size: 75%;
        background-position: top;

        @include max-w-md {
            padding-top: 0;
        }
    }
}
