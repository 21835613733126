.divider {
    width: 380px;
    height: 1px;
    background: $dv-gray-light-80;

    @include max-w-md {
        width: 0;
    }

    &__bio {
        height: 1px;
        background: $dv-gray-light-80;
        width: 100%;
    }
}

.vertical-divider {
    width: 1px;
    height: 80px;
    background: $dv-gray-light-80;
}

.labs {
    &__decor {
        background-image: url("../images/svg/labs-bg.svg");
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 1180px;
        top: 7px;
        padding-bottom: 250px;
        left: 50%;
        transform: translateX(-50%);

        @include max-w-lg {
            width: 100%;
        }

        @include max-w-md {
            width: 72%;
        }

        & :first-child {
            position: relative;
            top: 240px;

            @include max-w-md {
                width: 108px;
                height: 108px;
                top: 350px;
                left: -30px;
            }
        }

        & :last-child {
            position: relative;
            top: -150px;

            @include max-w-md {
                width: 108px;
                height: 108px;
                top: -55px;
                right: -80px;
            }

            @include max-w-sm {
                right: -40px;
            }

            @include max-w-xs {
                right: -20px;
            }
        }
    }
}
