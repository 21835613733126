.text-input {
    padding: 16px 20px;
    display: inline-block;
    border: 1px solid $dv-gray-light-100;
    border-radius: 6px;
    background: transparent;
    height: 54px;
    outline: none;
    color: $white;

    &--area {
        height: auto;
    }

    &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $gray;
    }

    &:focus {
        border-color: $blue-50;
    }
}
