.contact {
    &__social {
        & :not(:first-child) {
            margin-left: 16px;
        }
    }

    &__decor {
        left: 55%;
        bottom: -100px;

        @include max-w-sm {
            bottom: initial;
            top: -40px;
        }
    }

    &__title {
        @include max-w-md {
            max-width: 400px;
        }

        @include max-w-sm {
            max-width: 320px;
        }
    }
}
