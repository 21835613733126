.primary {
    background-image: url("../images/starlight-bg.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    position: relative;
    margin-top: -104px;
    overflow: hidden;

    &__inner {
        padding-top: 104px;
        padding-bottom: 143px;
        overflow: hidden;

        &.contact {
            padding-top: 306px;
            padding-bottom: 300px;

            @include max-w-sm {
                padding-top: 126px;
                padding-bottom: 64px;
            }
        }

        @include max-w-md {
            padding-top: 104px;
            padding-bottom: 39px;
        }
    }

    &__connect {
        // &.contacts {
        //     top: 400px;
        // }

        @include max-w-md {
            // position: absolute;
            width: 100px;
            height: 102px;
            top: 350px;
            z-index: 14;

            & a {
                width: 100px;
                height: 102px;
            }
        }

        @include max-w-sm {
            top: 250px;
        }

        @include max-w-xs {
            top: 240px;
        }
    }

    &__title {
        position: relative;
        // margin-top: 70px;

        // @include max-w-md {
        //     margin-top: 30px;
        // }

        & h1 {
            & nobr:first-of-type {
                margin-left: 98px;

                @include max-w-md {
                    content: "";
                    margin-left: 12px;
                }
            }

            & nobr:last-of-type {
                &::before {
                    content: url(../images/svg/dash.svg);
                    position: relative;
                    bottom: 15px;
                    margin-right: 56px;

                    @include max-w-md {
                        content: url(../images/svg/dash-mobile.svg);
                        margin-right: 14px;
                        bottom: 10px;
                    }
                }
            }

            & span {
                color: $blue;
            }
        }
    }

    &__dash {
        position: absolute;
        bottom: 34px;
    }

    &.secondary-bg {
        background-image: url("../images/secondary-section-main-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;

        @include max-w-md {
            background-image: url("../images/starlight-bg.png");
        }

        & .primary__inner {
            padding-bottom: 0;
            padding-top: 260px;

            &.solution {
                padding-bottom: 180px;
                padding-top: 225px;

                @include max-w-md {
                    padding-top: 96px;
                }
            }

            @include max-w-md {
                padding-top: 96px;
            }
        }
    }
}

.platforms {
    position: relative;

    &__inner {
        background-image: url("../images/platforms-bg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        min-height: 308px;
        margin-top: 80px;
        padding: 50px 60px;
        border-radius: 20px;

        @include max-w-sm {
            padding: 30px;
        }
    }

    &__tels {
        position: absolute;
        bottom: -1px;
        right: 0;
        max-width: 584px;
        border-style: none;
        border: 0;
        outline: none;
        box-shadow: none;

        & img {
            border-style: none;
            border: 0;
            outline: none;
            box-shadow: none;
        }

        & img[loading="lazy"] {
            clip-path: inset(0.5px);
        }

        @include max-w-lg {
            // left: 400px;
            max-width: 524px;
        }

        @media (max-width: 1099px) {
            max-width: 504px;
            right: -60px;
        }

        @include max-w-md {
            z-index: -10;
            top: -240px;
            width: 60%;
            height: 60%;
            min-width: 353px;
            min-height: 202px;
            left: auto;
            right: 0;
        }

        @include max-w-sm {
            top: -200px;
        }

        @include max-w-xs {
            top: -180px;
            right: -15px;
            width: 353px;
            height: 202px;

            & img {
                width: 353px;
                height: 253px;
                border-style: none;
                border: 0;
                outline: none;
                box-shadow: none;
            }
        }
    }

    // &.company-logos {
    //     & > img {
    //         @include max-w-md {
    //             max-width: 135px;
    //             max-height: 33;
    //         }
    //     }
    // }

    @include max-w-md {
        position: relative;
        z-index: 5;
        margin-top: 210px;
    }

    @include max-w-lg {
        flex-direction: column;
    }
}
