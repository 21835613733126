.slider-wrap {
    overflow-x: hidden;

    @include max-w-md {
        margin-left: -20px;
        margin-right: -20px;
    }

    & .swiper-container {
        @include max-w-md {
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}
