.labs-animation {
    background-image: url("../images/labs-main-bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 236px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    &.solutions {
        padding-top: 0;
    }

    @include max-w-xl {
        background-size: cover;
    }

    @include max-w-md {
        align-items: flex-start;
        padding-top: 100px;
        background-repeat: repeat;
        background-size: contain;
    }

    &__container {
        background-repeat: no-repeat;
        background-position: top;
        background-size: contain;
        width: 77%;

        @include max-w-xs {
            width: 100%;
        }

        &--design {
            background-image: url("../images/svg/design-bg.svg");
        }

        &--strategic {
            background-image: url("../images/svg/strategic-decor.svg");
            background-position: center;
        }

        &--research {
            background-image: url("../images/svg/research-decor.svg");
            background-position: center;
        }
    }
}

.animation {
    position: relative;
    width: 55%;
    display: flex;
    justify-content: flex-end;

    @include max-w-md {
        justify-content: flex-start;
    }

    &__design {
        width: 74%;

        @include max-w-md {
            width: 100%;
        }
    }

    &__strategic {
        width: 92%;
    }

    &__research {
        width: 95%;
    }

    &__vision {
        width: 80%;
    }

    &__what-we-do {
        width: 72%;
    }

    & > img {
        position: absolute;
        bottom: 30px;
        left: 65px;
        width: 100%;
    }

    & img:last-of-type {
        left: 240px;
        width: 90%;
        bottom: 60px;
    }

    @include max-w-md {
        width: 100%;
    }

    &--reverse {
        justify-content: flex-start;

        & > img {
            left: auto;
            right: 65px;
        }

        & img:last-of-type {
            right: 50px;
            left: auto;
            bottom: -20px;
            z-index: -1;
        }
    }

    &.solution-main {
        position: relative;
        left: 8%;
        justify-content: flex-end;

        @include max-w-xl {
            left: 2%;
        }

        @include max-w-md {
            left: initial;
            top: 50%;
            position: absolute;
        }

        @include max-w-xs {
            right: -30px;
        }

        & .animation__design {
            width: 100%;

            @include max-w-md {
                width: 30%;
            }

            @include max-w-sm {
                width: 45%;
            }

            @include max-w-xs {
                width: 73%;
            }
        }

        & img:last-of-type {
            left: 65px;
            bottom: 30px;

            @include max-w-md {
                display: none;
            }
        }
    }
}
