.services {
    background-image: url("../images/svg/services-decor.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    padding-bottom: 188px;

    @include max-w-md {
        padding-bottom: 80px;
    }

    & .swiper-wrapper {
        @include min-w-lg {
            max-width: none;
            justify-content: center !important;
            display: flex;
            gap: 40px;
            flex-wrap: wrap;
        }

        @include max-w-md {
            max-width: none;
            width: 360px;
        }

        @include max-w-md {
            display: flex;
            justify-content: space-between;
        }
    }

    &__card {
        min-width: 272px;
        border-radius: 8px;
        background-color: $bg-dark-80;
        padding: 34px 30px;
        width: min-content;
        min-height: 372px;

        &:not(:last-child) {
            @include max-w-md {
                margin-right: 20px;
            }
        }
    }
}
