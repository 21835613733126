.progress-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    & :not(:last-child) {
        margin-right: 9px;

        @include max-w-xs {
            margin-right: 6px;
        }
    }
}

.choosing-btn {
    display: grid;
    grid-auto-columns: auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    margin-top: 20px;
    padding-bottom: 32px;
    border-bottom: 1px solid $dv-gray-light-80;

    & > div {
        width: 268px;
        height: 54px;
        background: $bg-dark-60;
        border-radius: 6px;
    }

    @include max-w-sm {
        grid-template-columns: auto;

        & > div {
            width: 100%;
        }
    }
}

.connect-content {
    width: max-content;
    min-width: 664px;
    padding: 54px;

    @include max-w-sm {
        min-width: 0;
        width: 90%;
        padding: 30px;
    }
}

.calendly-content {
    width: max-content;
    min-width: 910px;
    padding: 54px;
}

.calendly-inline-widget {
    width: 100%;
    height: 600px;
}

.tab {
    display: none;
}

.step {
    width: 104px;
    background-color: $dv-gray-light-80;
    height: 2px;
    cursor: pointer;

    @include max-w-sm {
        width: 78px;
    }

    @include max-w-xs {
        width: 48px;
    }

    @include max-w-2xs {
        width: 38px;
    }
}

.step.active {
    background-color: $blue;
}

.connect-questions {
    & > span {
        color: $white;
        margin-right: 7px;
    }
}
