.radio-container {
    position: relative;
    padding-left: 45px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    user-select: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.radio-container input {
    position: absolute;
    opacity: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 18px;
    width: 18px;
    border: 1px solid #383a5f;
    background: transparent;
    border-radius: 50%;
    margin-left: 16px;
}

.radio-container input:checked ~ div .checkmark {
    background: rgba(0, 135, 205, 0.3) !important;
    box-shadow: 1px 1px 1px rgba(18, 185, 255, 0.3), inset 1px 1px 1px rgba(0, 0, 0, 0.07);
    border: none;
}

.radio-container input:hover ~ div .checkmark::after {
    display: block;
    width: 5px;
    height: 7px;
    border: solid $dv-gray-light-100;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: relative;
    top: 25%;
    left: 35%;
}

.checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.radio-container input:checked ~ div .checkmark::after {
    display: block;
}

.radio-container > div > .checkmark::after {
    width: 5px;
    height: 7px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: relative;
    top: 25%;
    left: 35%;
}

.radio-container input:checked {
    & ~ div {
        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
        background: linear-gradient(116.21deg, #12b9ff 18%, #8629d5 94.69%);
        width: 100%;
        height: 100%;
        border-radius: 6px;

        &:hover {
            background: linear-gradient(116.21deg, #30d7ff 18%, #a447f3 94.69%);
        }

        & label {
            margin-left: 45px;
        }
    }
}
