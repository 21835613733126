.achievements {
    padding: 0;

    &__inner {
        overflow: hidden;
        max-width: 1134px;
        padding-bottom: 80px;

        @include max-w-md {
            padding-bottom: 0;
        }
    }

    &__card-count {
        min-height: 164px;
        background-image: url("../images/svg/achievements-screw.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;

        & > p {
            font-weight: 600;
            font-size: 70px;
            line-height: 84px;
            color: $white;
        }
    }

    &__block {
        @include max-w-md {
            padding-top: 15px;
        }

        padding-top: 25px;

        & > div {
            @include max-w-md {
                margin-top: 15px;
            }
        }

        & div:nth-of-type(1) {
            & .achievements__decor {
                & img:first-of-type {
                    left: 25px;
                    top: 5px;
                }

                & img:last-of-type {
                    left: 90px;
                    top: 80px;
                }
            }
        }

        & div:nth-of-type(2) {
            & .achievements__decor {
                & img:first-of-type {
                    left: -60px;
                    top: 50px;
                }

                & img:last-of-type {
                    left: 170px;
                    top: 15px;
                }
            }
        }

        & div:nth-of-type(3) {
            & .achievements__decor {
                & img:first-of-type {
                    left: -55px;
                    top: 0;
                }

                & img:last-of-type {
                    left: 175px;
                    top: 70px;
                }
            }
        }
    }

    &-bg {
        background-image: url("../images/svg/achievements-decor.svg");
        background-repeat: no-repeat;
        background-size: 55%;
        background-position: bottom;
    }

    &__decor {
        position: absolute;
        width: 190px;
        height: 160px;

        & img:first-of-type {
            position: absolute;
            z-index: -1;
        }

        & img:last-of-type {
            position: absolute;
        }
    }
}
