h1 {
    @include responsive-fz(70, 22);

    line-height: 120%;
    font-weight: 500;
    color: $white;

    & nobr:last-of-type {
        color: $blue;
    }
}

h2 {
    @include responsive-fz(60, 28);

    line-height: 142%;
    font-weight: 500;
    color: $white;
}

h3 {
    @include responsive-fz(40, 28);

    line-height: 142%;
    font-weight: 500;
    color: $white;
}

h4 {
    @include responsive-fz(24, 22);

    line-height: 133%;
    font-weight: 500;
}

h5 {
    @include responsive-fz(20, 15);

    line-height: 140%;
    font-weight: 500;
}

@include min-w-xl {
    h1 {
        font-size: 70px;
        line-height: 138%;
    }

    h2 {
        font-size: 60px;
    }

    h3 {
        font-size: 40px;
    }

    h4 {
        font-size: 24px;
    }

    h5 {
        font-size: 20px;
    }
}
