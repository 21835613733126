.our-team {
    background-image: url("../images/svg/our-team-bg.svg");
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: top;

    &__card {
        padding: 12px 12px 30px 12px;
        background: $bg-dark-80;
        min-width: 272px;
        border-radius: 8px;

        & > img {
            border-radius: 8px;
        }

        & > div {
            padding-left: 18px;

            @include max-w-md {
                padding-left: 0;
            }
        }

        &:not(:last-child) {
            @include max-w-md {
                margin-right: 20px;
            }
        }
    }

    &__container {
        @include min-w-lg {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 40px;
        }
    }

    & .swiper-wrapper {
        @include min-w-lg {
            max-width: none;
            justify-content: center !important;
        }

        @include max-w-md {
            max-width: none;
            width: 360px;
        }

        @include max-w-md {
            display: flex;
            justify-content: space-between;
        }
    }

    @include max-w-md {
        min-width: 280px;
    }
}

.bio-modal {
    display: none;
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    // @include max-w-sm {
    // }
}

.bio-content {
    background-color: $bg-dark-80;
    border-radius: 8px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 29px;
    width: 35%;

    @include max-w-lg {
        width: 60%;
    }

    @include max-w-sm {
        width: 90%;
        padding: 20px;
    }

    &__text {
        padding: 25px;

        & img {
            width: 66px;
            height: 66px;
            border-radius: 50%;
        }

        @include max-w-sm {
            padding: 4px;
        }
    }
}

.close {
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
