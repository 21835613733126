.review {
    padding-top: 245px;
    background-image: url("../images/review-bg.png");
    overflow-x: hidden;
    padding-bottom: 52px;

    @include max-w-md {
        padding-top: 64px;
        padding-bottom: 58px;
        background-size: contain;
    }

    & .review-text {
        font-weight: 600;
        font-size: 70px;
        line-height: 84px;
        color: $white;

        @include max-w-md {
            font-size: 46px;
            line-height: 66px;
        }

        @include max-w-sm {
            font-size: 26px;
            line-height: 36px;
        }

        & span {
            color: $blue;

            &::before {
                content: url(../images/svg/dash.svg);
                position: relative;
                bottom: 15px;
                margin-right: 56px;

                @include max-w-md {
                    content: url(../images/svg/dash-mobile.svg);
                    margin-right: 14px;
                    bottom: 10px;
                }
            }
        }
    }

    &__decor {
        position: absolute;
        bottom: -150px;
        z-index: -1;
        right: 0;

        & > img {
            height: 650px;
        }
    }

    & .review-shape {
        position: absolute;
        bottom: 0;
        width: 85%;
        left: 45%;
        transform: translateX(-50%);

        & :first-child {
            position: absolute;
            left: 0;
            bottom: 380px;

            @include max-w-xl {
                bottom: 400px;
                // width: 145px;
                // height: 145px;
            }

            @include max-w-md {
                top: -410px;
                width: 145px;
                height: 145px;
                left: 80%;
            }

            @include max-w-xs {
                top: -450px;
            }
        }

        & :last-child {
            position: absolute;
            right: 0;
            bottom: 170px;

            @include max-w-xl {
                bottom: 0;
                // width: 145px;
                height: 225px;
            }

            @include max-w-md {
                bottom: 50px;
                width: 145px;
                height: 145px;
            }
        }

        @include max-w-md {
            width: 100%;
        }
    }

    & .review-decor {
        background-image: url("../images/svg/review-decor-bg.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
    }

    & .review-connect {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: max-content;

        @include max-w-xs {
            left: 0;
            transform: translateX(0);
            margin-top: 44px;

            & a {
                width: 100px;
                height: 102px;
            }
        }
    }
}
