.grid {
    display: grid;
    grid-auto-columns: auto;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;

    &__holder {
        grid-column: span 2;
        grid-row: auto;
    }

    &__btn {
        @include max-w-sm {
            grid-column: span 2;
            grid-row: span 1;
            order: 3;
        }
    }
}

.three-items {
    @include min-w-xl {
        grid-column-gap: 1.5em;
        grid-row-gap: 1.5em;
        grid-template-columns: repeat(3, 0.33fr);
    }

    grid-template-columns: repeat(2, 0.5fr);
    display: grid;
    grid-auto-columns: auto;
    grid-column-gap: 20px;
    grid-row-gap: 2.5em;
    grid-template-rows: auto;
    align-items: center;

    @include min-w-sm {
        grid-column-gap: 1.5em;
        grid-row-gap: 1.5em;
        grid-template-columns: repeat(3, 0.33fr);
    }
}
