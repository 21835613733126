.static {
    position: static;
}

.fixed {
    position: fixed;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.sticky {
    position: sticky;
}

.md-max\:pos {
    @include max-w-md {
        &-static {
            position: static;
        }

        &-absolute {
            position: absolute;
        }
    }
}
