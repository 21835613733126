.header {
    transition: 0.3s;
    position: sticky;
    z-index: 15;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &__inner {
        max-width: 1600px;
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }

    &.scroll-down {
        transform: translate3d(0, -100%, 0);
    }

    &.scroll-up {
        transform: none;
        background-color: $bg-dark;
        background-image: url("../images/starlight-bg.png");
        background-position: top;
    }
}
