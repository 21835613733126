.not-found {
    height: 960px;

    @include max-w-xs {
        height: 560px;
    }

    & p {
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: $gray;

        @include max-w-xs {
            font-size: 18px;
            line-height: 28px;
        }
    }

    &__decor {
        position: absolute;
        width: 100%;
        height: 100%;

        & img:first-of-type {
            position: absolute;
            left: -60px;
            top: -30px;
            width: 143px;
            height: 130px;

            @include max-w-xs {
                width: 80px;
                height: 73px;
                left: 10px;
                top: -20px;
            }
        }

        & img:last-of-type {
            position: absolute;
            right: -110px;
            bottom: -40px;
            width: 155px;
            height: 155px;
            z-index: 2;

            @include max-w-xs {
                width: 78px;
                height: 78px;
                right: 0;
                bottom: -25px;
            }
        }
    }

    &__text {
        z-index: 1;

        & > p {
            font-size: 190px;
            font-weight: 600;
            line-height: 202px;
            color: $white;

            @include max-w-xs {
                font-size: 70px;
                line-height: 84px;
            }
        }
    }

    &__card-count {
        min-height: 164px;
        width: 100%;

        @include max-w-xs {
            min-height: 84px;
        }
    }
}
