.lh {
    &-120 {
        line-height: 120%;
    }

    &-129 {
        line-height: 129%;
    }

    &-130 {
        line-height: 130%;
    }

    &-143 {
        line-height: 142.9%;
    }

    &-150 {
        line-height: 150%;
    }

    &-162 {
        line-height: 162%;
    }
}
