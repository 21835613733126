.our-projects {
    padding-top: 120px;

    &.stars-bg {
        background-image: url("../images/projects-bg.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top;
    }

    & .projects-bg {
        background-image: url("../images/svg/our-team-bg.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top;
    }

    @include max-w-md {
        padding-top: 72px;
    }

    &__counter {
        & :first-child {
            font-weight: 600;
            font-size: 40px;
            line-height: 54px;
            color: $white;

            @include max-w-md {
                font-size: 26px;
                line-height: 36px;
            }
        }

        & :last-child {
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: $gray;

            @include max-w-md {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }

    &__text {
        & :first-child {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: $white;
            margin-top: 24px;

            @include max-w-md {
                font-size: 18px;
                line-height: 28px;
            }
        }

        & :last-child {
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: $gray;
            margin-top: 12px;

            @include max-w-md {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }

    & .swiper-wrapper {
        @include min-w-lg {
            max-width: none;
        }

        @include max-w-md {
            max-width: none;
            width: 360px;
        }

        display: flex;
        justify-content: space-between;
    }

    &__slide {
        width: 360px;
        height: 560px;
        background-color: $bg-dark-80;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        // padding: 40px;
        // background-size: cover;
        // background-repeat: no-repeat;
        // background-position: bottom;
        border-radius: 8px;

        & > div {
            padding: 40px 40px 0;
            height: 350px;

            // &::after {
            //     content: "";
            //     display: block;
            //     padding-bottom: 250px;
            // }

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;

            & > img {
                max-height: 42px;

                @include max-w-md {
                    max-height: 40px;
                }
            }

            @include max-w-lg {
                padding: 30px 30px 0;
            }
        }

        @include max-w-lg {
            max-width: none;
        }

        & > img {
            position: absolute;
            bottom: 0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        // &--first {
        //     background-image: url("../images/first-project-bg.png");
        // }

        // &--second {
        //     background-image: url("../images/second-project-bg.png");
        // }

        // &--third {
        //     background-image: url("../images/third-project-bg.png");
        // }

        @include max-w-md {
            min-width: 280px;
        }
    }
}
