@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@400;500;600&display=swap");

/* Fonts */
$font-primary: "Prompt", sans-serif;

/* Colors */
$success: #73ce17;
$error: #ff0c0c;

/* Text */
$gray: #a096bd;
$white: #ffffff;
$white-80: rgba(255, 255, 255, 0.8);
$white-60: rgba(255, 255, 255, 0.6);
$dark: #3c3259;

/* Design */
$blue-50: rgba(12, 129, 255, 0.5);
$blue: #0c81ff;
$gradient-1: linear-gradient(124.29deg, #1485ff 21.08%, #0071fa 37.15%, #0071fa 68.11%);
$gradient-2: linear-gradient(116.21deg, #12b9ff 18%, #8629d5 69.42%);

/* Background */
$bg-dark: #06082d;
$bg-dark-80: #101237;
$bg-dark-60: #1a1c41;

/* Dividers */
$dv-gray-light-80: #2e3055;
$dv-gray-light-100: #383a5f;

$transition: 0.2s;
