.hamburger {
    position: relative;
    width: 1.444em;
    height: 1.444em;
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    outline: none;
    border: none;

    &.is-active {
        & > span {
            transform: rotate(45deg);
            width: 13px;
            top: 11px;

            &::before {
                top: 0;
                transform: rotate(0deg);
                width: 13px;
            }

            &::after {
                top: 0;
                transform: rotate(90deg);
                width: 13px;
            }

            @include min-w-sm {
                width: 27px;

                &::before {
                    width: 27px;
                }

                &::after {
                    width: 27px;
                }
            }
        }
    }
}

.hamburger > span {
    width: 12px;
    right: 0;

    &,
    &::before,
    &::after {
        background-color: $white;
        height: 1.5px;
        position: absolute;
        transition-duration: 0.3s;
        border-radius: 10px;
        right: 0;
    }

    &::before {
        content: "";
        top: -5px;
        width: 17px;
    }

    &::after {
        content: "";
        top: 5px;
        width: 17px;
    }

    @include min-w-sm {
        width: 22px;
        right: 0;

        &,
        &::before,
        &::after {
            background-color: $white;
            height: 2px;
            position: absolute;
            transition-duration: 0.3s;
            border-radius: 10px;
            right: 0;
        }

        &::before {
            content: "";
            top: -8px;
            width: 27px;
        }

        &::after {
            content: "";
            top: 8px;
            width: 27px;
        }
    }
}
