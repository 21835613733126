.learn-more {
    border-radius: 20px;
    padding: 37px 85px 38px 74px;
    background-image: url("../images/platforms-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include max-w-md {
        background-image: url("../images/learn-more-mobile.png");
        padding: 30px;
    }

    @include max-w-2xs {
        padding: 20px 15px 30px 15px;
    }
}
