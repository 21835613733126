.navbar {
    @include max-w-lg {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 1.111em;
        padding-bottom: 1.111em;
    }

    &__nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 1.667em;
        padding-bottom: 1.667em;

        @include max-w-lg {
            align-items: flex-start;
            width: 100%;
            position: fixed;
            flex-direction: column;
            top: 0;
            text-align: center;
            left: -100%;
            z-index: -1;
            margin: 0;
            padding: 20px 20px 68px;
            height: 100vh;
            overflow: auto;
            will-change: transform;
            transition: transform 0.3s cubic-bezier(0.35, 0.15, 0.34, 1.06), z-index 0s 0.3s;
            background-image: url("../images/menu-bg.jpg");
            background-repeat: no-repeat;
            background-size: cover;

            &.is-active {
                transform: translateX(100%);
                z-index: 10;
                transition: transform 0.3s cubic-bezier(0.35, 0.15, 0.34, 1.06), z-index 0s 0s;
            }
        }

        & > ul {
            display: flex;
            align-items: center;

            & li:last-of-type {
                margin-right: 0;
            }

            @include max-w-lg {
                flex-direction: column;
                width: 100%;
            }

            & > li {
                text-align: center;

                @include max-w-lg {
                    margin-top: 20px;
                }

                @include min-w-xl {
                    margin-right: 1.667em;
                }

                & > a {
                    font-size: 0.889em;
                    line-height: 26px;
                    font-weight: 500;
                    color: $gray;

                    @include max-w-lg {
                        font-weight: 400;
                        font-size: 26px;
                        line-height: 36px;
                    }
                }

                &:hover {
                    & > a {
                        color: $white;
                    }
                }

                &.menu-item-has-children {
                    position: relative;

                    &:hover,
                    &:active {
                        & > a {
                            color: $white;

                            &::before,
                            &::after {
                                transform: translateY(-45%) rotate(180deg);
                            }

                            &::before {
                                opacity: 0;
                            }

                            &::after {
                                opacity: 1;
                            }
                        }

                        & > .sub-menu {
                            opacity: 1;
                            pointer-events: auto;
                            z-index: 10;
                        }
                    }

                    & > a {
                        position: relative;
                        display: inline-flex;
                        align-items: center;
                        padding-right: 17px;

                        &::before,
                        &::after {
                            content: url("../images/svg/arrow.svg");
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translateY(-59%) rotate(0deg);
                            transform-origin: center;
                            will-change: opacity;
                            transition: transform $transition, opacity $transition;
                        }

                        &::before {
                            opacity: 1;
                        }

                        &::after {
                            content: url("../images/svg/arrow-hover.svg");
                            opacity: 0;
                        }
                    }

                    & > .sub-menu {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        transform: translateX(-40%);
                        width: 440px;
                        opacity: 0;
                        transition: opacity $transition;
                        pointer-events: none;
                        background-color: $bg-dark-80;
                        border-radius: 8px;

                        @include max-w-md {
                            width: 300px;
                            transform: translateX(-30%);
                        }

                        & > li {
                            &:hover {
                                background-color: $bg-dark;
                                border-radius: 8px;
                            }

                            padding: 20px 40px 0;

                            @include max-w-md {
                                padding: 20px 20px 0;
                            }

                            &:not(:last-child) {
                                & > a {
                                    & > p {
                                        border-bottom: 1px solid $dv-gray-light-100;
                                    }
                                }
                            }

                            & > a {
                                text-align: left;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 26px;
                                color: $white;
                                display: flex;
                                flex-direction: column;

                                & > p {
                                    padding: 4px 0 20px;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 22px;
                                    color: rgba(255, 255, 255, 0.6);
                                }
                            }
                        }

                        & li:first-of-type {
                            padding: 35px 40px 0;

                            @include max-w-md {
                                padding: 20px 20px 0;
                            }
                        }

                        & li:last-of-type {
                            padding: 20px 40px 15px;

                            @include max-w-md {
                                padding: 20px 20px 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &__logo {
        @include max-w-xs {
            width: 156px;
        }
    }

    &__links {
        @include max-w-lg {
            flex-direction: column;
        }
    }

    &__social {
        width: 128px;
        cursor: pointer;

        & > a {
            & > svg {
                & > path {
                    transition: fill 0.2s;
                }
            }

            &:hover {
                & > svg {
                    & > path {
                        fill: $blue;
                    }
                }
            }
        }

        @include max-w-lg {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    &__toggler {
        @include min-w-xl {
            display: none;
        }

        z-index: 15;
    }
}
