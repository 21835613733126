.footer {
    flex-shrink: 0;
    background: $bg-dark-80;
    padding: 60px 0;

    @include max-w-md {
        padding: 30px 0;
    }

    &__social {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 114px;

        & > a {
            & > svg {
                & > path {
                    transition: fill 0.2s;
                }
            }

            &:hover {
                & > svg {
                    & > path {
                        fill: $blue;
                    }
                }
            }
        }

        & p {
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: $white;
        }
    }

    &__navbar {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 20px;
    }

    &__rights {
        & > img {
            margin-bottom: 20px;
        }
    }

    &__nav {
        display: flex;
        justify-content: space-between;
        width: 50%;

        @include max-w-md {
            display: none;
        }

        & p {
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: $white;
            margin-bottom: 10px;
        }

        & ul {
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;

            & > li {
                margin-top: 6px;

                & > a {
                    color: $gray;

                    &:hover {
                        color: $white;
                    }
                }
            }
        }
    }
}

.footer-address {
    & > p {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $gray;
    }
}

.navbar {
    &__social {
        margin-bottom: 0 !important;

        & img {
            & > svg {
                & > path {
                    fill: #0c81ff;
                }
            }
        }
    }
}
