.btn {
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    outline: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: transparent;

    &--primary {
        border: 1px solid rgba(12, 129, 255, 0.5);
        border-radius: 6px;
        font-size: 0.778em;
        color: $white;
        padding: 1.063em 1.5em;
        transition: color 150ms ease-in-out;
        z-index: 1;

        &::after {
            content: "";
            position: absolute;
            display: block;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 100%;
            height: 0;
            background: $blue;
            z-index: -1;
            transition: height 150ms ease-in-out;
            border-radius: 6px;
        }

        &::before {
            content: "";
            position: absolute;
            display: block;
            top: 50%;
            left: 0;
            transform: translateX(-50%);
            width: 100%;
            height: 0;
            background: $blue;
            z-index: -1;
            transition: height 150ms ease-in-out;
            border-radius: 6px;
        }

        &:hover {
            color: $white;

            &::after {
                height: 100%;
            }
        }
    }

    &--header {
        border: 1px solid rgba(12, 129, 255, 0.5);
        border-radius: 6px;
        font-size: 0.778em;
        color: $white;
        padding: 0.929em 1.429em;

        &::after {
            content: "";
            position: absolute;
            display: block;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 100%;
            height: 0;
            background: $blue;
            z-index: -1;
            transition: height 150ms ease-in-out;
            border-radius: 6px;
        }

        &::before {
            content: "";
            position: absolute;
            display: block;
            top: 50%;
            left: 0;
            transform: translateX(-50%);
            width: 100%;
            height: 0;
            background: $blue;
            z-index: -1;
            transition: height 150ms ease-in-out;
            border-radius: 6px;
        }

        &:hover {
            color: $white;

            &::after {
                height: 100%;
            }
        }
    }

    &--arrow {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: rgba(20, 133, 255, 1);
    }

    &--review {
        background: linear-gradient(124.29deg, #1485ff 21.08%, #0071fa 37.15%, #0071fa 68.11%);
        box-shadow: 0 14px 34px rgba(10, 53, 190, 0.4);
        border-radius: 8px;
        padding: 14px 24px;
        color: $white;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        border: none;

        &:hover {
            background: linear-gradient(124.29deg, #32a3ff 21.08%, #1e8fff 37.15%, #1485f5 68.11%);
        }

        @include max-w-sm {
            width: 100%;
        }
    }

    &--connect {
        border: none;

        &:hover {
            & > svg {
                transform: rotate(40deg);

                & .arrow {
                    transform: rotate(-85deg);
                    transform-origin: 50% 50%;
                    transition: transform $transition;
                }

                transition: transform $transition;

                & .circle-bg {
                    fill-opacity: 1;
                    transition: fill-opacity $transition;
                }
            }
        }
    }
}
