.comments {
    background-image: url("../images/svg/comments-decor.svg");
    background-size: 83%;
    background-repeat: no-repeat;
    background-position: bottom;

    & .swiper-wrapper {
        @include min-w-lg {
            max-width: none;
            justify-content: center !important;
            display: flex;
            gap: 40px;
            flex-wrap: wrap;
        }

        @include max-w-md {
            max-width: none;
            width: 360px;
        }

        @include max-w-md {
            display: flex;
            justify-content: space-between;
        }
    }

    &__text {
        border-top: 1px solid $dv-gray-light-80;
        padding-top: 28px;
    }

    &__quote {
        top: 0;
        right: 0;
    }

    &__avatar-container {
        border-radius: 50%;
        border: 2px solid;
        padding: 4px;
        background-image: linear-gradient(180deg, rgba(18, 185, 255, 1) 50%, rgba(134, 41, 213, 1) 100%);
        width: 70px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
            border: 6px solid $bg-dark-80;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            max-width: none;
        }
    }

    &__square {
        left: 0;
        top: 0;
        transform: translate(-40%, -40%);

        @include max-w-lg {
            display: none;
        }
    }

    &__card {
        min-width: 376px;
        border-radius: 8px;
        background-color: $bg-dark-80;
        padding: 40px 38px 40px 40px;
        width: min-content;
        min-height: 337px;

        @include max-w-sm {
            min-width: 280px;
            min-height: 290px;
            padding: 30px;
        }

        &:not(:last-child) {
            @include max-w-md {
                margin-right: 20px;
            }
        }
    }
}
