*,
::after,
::before {
    box-sizing: border-box;
}

body {
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
}

@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

textarea {
    resize: vertical;
}

::-ms-clear {
    display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

select::-ms-expand {
    display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p {
    margin: 0;
}

ul,
ol {
    padding: 0;
    list-style: none;
}

img {
    max-width: 100%;
    display: block;
    margin: 0;
}

a {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
}
