.text {
    &-center {
        text-align: center;
    }

    &-uppercase {
        text-transform: uppercase;
    }

    &-xs {
        font-size: 0.875em;
    }

    &-sm {
        font-size: 1em;
    }

    &-md {
        font-size: 1.125em;
    }

    &-lg {
        font-size: 1.25em;
    }

    &-xl {
        font-size: 2em;
    }

    &-xxl {
        font-size: 2.5em;
    }

    &-2xl {
        font-size: 3.625em;
    }
}

.sm-max\:text {
    @include max-w-sm {
        &-left {
            text-align: left;
        }

        &-center {
            text-align: center;
        }

        &-xs {
            font-size: 0.875em;
        }

        &-sm {
            font-size: 1em;
        }

        &-md {
            font-size: 1.125em;
        }

        &-lg {
            font-size: 1.25em;
        }

        &-xxl {
            font-size: 1.625em;
        }
    }
}
