.color {
    &-white {
        color: $white;
    }

    &-white-80 {
        color: $white-80;
    }

    &-gray {
        color: $gray;
    }
}
