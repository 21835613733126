.min-w {
    &-0 {
        min-width: 0;
    }

    &-128 {
        min-width: 128px;
    }

    &-440 {
        min-width: 440px;
    }

    &-570 {
        min-width: 570px;
    }
}

.min-h {
    &-420 {
        min-height: 420px;
    }
}

.max-h {
    &-400 {
        max-height: 400px;
    }
}

.max-w {
    &-302 {
        max-width: 302px;
    }

    &-470 {
        max-width: 470px;
    }

    &-522 {
        max-width: 522px;
    }

    &-543 {
        max-width: 543px;
    }

    &-570 {
        max-width: 570px;
    }

    &-586 {
        max-width: 586px;
    }

    &-674 {
        max-width: 674px;
    }

    &-700 {
        max-width: 700px;
    }

    &-718 {
        max-width: 718px;
    }

    &-790 {
        max-width: 790px;
    }

    &-1100 {
        max-width: 1050px;
    }
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.xs\:w-full {
    @include max-w-xs {
        width: 100%;
    }
}

.sm-max\:w-full {
    @include max-w-sm {
        width: 100%;
    }
}

.lg-max\:w-full {
    @include max-w-lg {
        width: 100%;
    }
}

.sm-max\:max-w {
    @include max-w-sm {
        &-none {
            max-width: none;
        }

        &-200 {
            max-width: 200px;
        }

        &-300 {
            max-width: 300px;
        }

        &-0 {
            min-width: 0;
        }
    }
}

.md-max\:max-w {
    @include max-w-md {
        &-400 {
            max-width: 400px;
        }

        &-340 {
            max-width: 340px;
        }
    }
}

.md-max\:min-w {
    @include max-w-md {
        &-0 {
            min-width: 0;
        }

        &-340 {
            min-width: 340px;
        }
    }
}

.sm-max\:min-w {
    @include max-w-sm {
        &-0 {
            min-width: 0;
        }
    }
}

.xs-max\:max-w {
    @include max-w-xs {
        &-none {
            max-width: none;
        }

        &-280 {
            max-width: 280px;
        }
    }
}
