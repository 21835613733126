.searchbox-wrap {
    position: relative;
    display: flex;
    margin-top: 8%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
    background-color: $white;

    & input {
        padding: 30px 20px 30px 62px;
        font-size: 1.1em;
        // border-top-left-radius: 12px;
        // border-bottom-left-radius: 12px;
        border: none;
        width: 100%;
        height: 64px;
        outline: none;
        background-color: transparent;

        @include max-w-sm {
            padding: 14px 0 14px 50px;
        }

        &::placeholder {
            font-size: 16px;
            line-height: 26px;
            color: $gray;
        }
    }

    & button {
        padding-right: 10px;
        background-color: transparent;
        // border-top-right-radius: 12px;
        // border-bottom-right-radius: 12px;
        box-shadow: 5px 4px 6px rgba(0, 0, 0, 0.19);
        border: none;
        cursor: pointer;
        cursor: hand;
        position: relative;

        @include max-w-md {
            width: 40%;
        }

        & img {
            position: absolute;
            left: 60px;
            bottom: 20px;
            margin-left: 20px;

            @include max-w-md {
                display: none;
            }
        }

        & span {
            padding: 13px 58px 13px 24px;
            font-size: 0.9em;
            font-weight: 300;
            color: $white;
            background-image: linear-gradient(124.29deg, #1485ff 21.08%, #0071fa 37.15%, #0071fa 68.11%);
            border-radius: 8px;
            box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.19);

            @include max-w-md {
                padding: 9px 20px;
            }

            @include max-w-2xs {
                padding: 5px 12px;
            }
        }
    }
}

.searchbox-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 24px;

    @include max-w-sm {
        left: 16px;
    }
}

@media screen and (max-width: 1195px) and (min-width: 1190px) {
    .searchbox-wrap {
        & button {
            & img {
                margin-left: 30px;
            }
        }
    }
}
